import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Modal, ContactForm, DemoForm } from 'components';
import Logo from 'assets/svgs/logo-contrast.svg';
import AXLogo from 'assets/svgs/ax-logo.svg';
import styled from 'styled-components';
import FB from 'assets/svgs/facebook-icon.svg';
import TW from 'assets/svgs/twitter-icon.svg';
import IN from 'assets/svgs/linkedin-icon.svg';
import INSTA from 'assets/svgs/insta-icon.svg';
import MOB from 'assets/svgs/mob-icon.svg';
import LOCATION from 'assets/svgs/location-icon.svg';
import { ThemeContext } from 'context';

export default () => {
  const {
    isMobile,
    headerRef,
    contactRef,
    casesRef,
    benefitsRef,
    modulesRef,
    scrollTo
  } = useContext(ThemeContext);
  return (
    <StyledFooter>
      <div className="container">
        <div className="grid">
          <Demo>
            <h4>See Event X In Action</h4>
            <Modal
              trigger={<button type="button">request a demo</button>}
              modalContent={<DemoForm />}
            />
          </Demo>

          <Help>
            <Modal
              trigger={
                <>
                  <h4>Want to know more?</h4>

                  <p>
                    <strong>Contact Agency X Today!</strong>
                  </p>
                </>
              }
              modalContent={<ContactForm />}
            />
          </Help>
          <SocialColumn>
            <h4>FIND US ON</h4>
            <Socials>
              <Social aria-label="Facebook" href="https://www.facebook.com/AgencyXdublin/" target="blank">
                <FB />
              </Social>
              <Social aria-label="Twitter" href="https://twitter.com/AgencyX_Dublin" target="blank">
                <TW />
              </Social>
              <Social aria-label="Linked In" href="https://www.linkedin.com/company/agencyx-dublin/" target="blank">
                <IN />
              </Social>
              <Social aria-label="Instagram" href="https://www.instagram.com/agency_x_dublin/" target="blank">
                <INSTA />
              </Social>
            </Socials>
          </SocialColumn>
          <ContactColumn>
            <div id="contact" ref={contactRef} />
            {!isMobile && (
              <LogoContainer>
                <Logo />
                <span>
                  Brought to you by{' '}
                  <a href="https://www.agencyx.ie" target="blank">
                    <AXLogo />
                  </a>
                </span>
              </LogoContainer>
            )}
            <h4>Contacts</h4>

            <Contact href="tel:+353 (0)1 6706122">
              <ContactIcon>
                <MOB />
              </ContactIcon>
              <span>+353 (0)1 6706122</span>
            </Contact>
            <Contact
              target="blank"
              href="https://maps.google.com/maps?q=Media+House,24+South+William+Street,Dublin+2,Ireland">
              <ContactIcon>
                <LOCATION />
              </ContactIcon>
              Media House, 24 South William Street, Dublin 2, Ireland
            </Contact>
          </ContactColumn>
          {!isMobile && (
            <SitemapColumn>
              <h4>sitemap</h4>
              <Link
                to="#header"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(headerRef);
                }}>
                Home
              </Link>
              <Link
                to="#benefits"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(benefitsRef);
                }}>
                Benefits
              </Link>
              <Link
                to="#modules"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(modulesRef);
                }}>
                How It Works
              </Link>
              <Link
                to="#cases"
                onClick={(e) => {
                  e.preventDefault();
                  scrollTo(casesRef);
                }}>
                Case Studies
              </Link>
            </SitemapColumn>
          )}

          {!isMobile && (
            <SupportColumn>
              <h4>support</h4>
              <Modal
                trigger={<button type="button">Request A Demo</button>}
                modalContent={<DemoForm />}
              />
              <Modal
                trigger={<button type="button">Contact Us</button>}
                modalContent={<ContactForm />}
              />
              <a target="blank" href="https://www.agencyx.ie/privacy-policy/">
                Legal & Privacy
              </a>
              <a target="blank" href="https://www.agencyx.ie/">
                Agency X
              </a>
            </SupportColumn>
          )}
        </div>
      </div>
      <div className="container">
        <FooterCopy>
          {isMobile && (
            <>
              <a target="blank" href="https://www.agencyx.ie/privacy-policy/">
                Legal & Privacy
              </a>
              <Logo />
              <span>
                Brought to you by <AXLogo />
              </span>
            </>
          )}

          <p>
            Event X™ and EventXLive.com™ are trademarks of{' '}
            <a href="https://agencyx.ie" target="blank">
              AgencyX™
            </a>{' '}
            Limited, all rights reserved. Designed and developed by Agency X™.
          </p>
        </FooterCopy>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.9);
  padding: 0.625rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  margin-top: 4rem;
  margin-bottom: 0;
  @media (min-width: 600px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    padding: 0.625rem;
    padding-top: 3rem;
    padding-bottom: 1rem;
    margin-top: 6rem;
  }
  .grid {
    grid-template-areas: 'demo' 'socials' 'contact' 'footercopy';
    @media (min-width: 1150px) {
      grid-template-areas: 'demo demo help help' 'contact socials sitemap support' '. footercopy footercopy .';
      grid-row-gap: 6rem;
    }
  }
  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
    color: white;
    margin-bottom: 1rem;
    letter-spacing: 0.1em;
    text-align: left;
    text-transform: uppercase;
  }
`;

const SocialColumn = styled.div`
  grid-area: socials;
  display: block;
  flex-direction: column;
  grid-column: 1/6;
  margin-bottom: 3rem;
  @media (min-width: 1150px) {
    grid-column: 9/12;
    margin-bottom: 0;
  }
  @media (min-width: 1440px) {
    grid-column: 10/12;
    margin-bottom: 0;
  }
`;

const Socials = styled.div`
  grid-area: socials;
  display: flex;
`;

const Social = styled.a`
  background: var(--ex-main);
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transition: 0.75s;
  svg {
    width: auto;
    height: auto;
    max-width: 20px;
    max-height: 20px;
    width: auto;
    transition: 0.75s;
    path {
      fill: white;
    }
  }
  :hover {
    background: var(--ex-beige);
    svg {
      path {
        fill: var(--ex-main);
      }
    }
  }
`;

const ContactColumn = styled.div`
  grid-area: contact;
  display: flex;
  flex-direction: column;
  grid-column: 1/6;
  margin-bottom: 3rem;
  align-items: flex-start;
  h4 {
    text-transform: capitalize;
  }
  @media (min-width: 1150px) {
    margin-bottom: 0;
    grid-column: 2/5;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.5em;
    color: white;
    margin-top: 1rem;
    svg {
      max-height: 0.75rem;
      margin-left: 0.5rem;
    }
  }
  svg {
    max-height: 1.5rem;
    width: auto;
  }
`;

const Contact = styled.a`
  display: flex;
  margin-bottom: 1rem;
  color: white;
  font-size: 1.125rem;

  :visited {
    color: white;
  }
`;

const ContactIcon = styled.div`
  background: var(--ex-main);
  border-radius: 50%;
  min-width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transition: 0.75s;
  svg {
    width: auto;
    height: auto;
    max-width: 16px;
    max-height: 16px;
    width: auto;
    transition: 0.75s;
    path {
      stroke: white;
    }
  }
  ${Contact}:hover & {
    background: var(--ex-beige);
    svg {
      path {
        stroke: var(--ex-main);
      }
    }
  }
`;

const FooterCopy = styled.div`
  grid-area: footercopy;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.625rem;
  a {
    margin-bottom: 2.5rem;
    color: white;
    font-size: 1.125rem;
    line-height: 2rem;

    :visited {
      color: white;
    }
  }
  svg {
    height: 1.5rem;
    width: auto;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25em;
    color: white;
  }
  @media (min-width: 1150px) {
    align-items: center;
  }
  span {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.5em;
    color: white;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    svg {
      max-height: 0.75rem;
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }
`;

const Demo = styled.div`
  grid-area: demo;
  grid-column: 1/6;
  background: var(--ex-main);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  margin-top: -9rem;
  margin-bottom: auto;
  padding: 0.625rem;
  @media (min-width: 768px) {
    margin-top: -6rem;
  }
  @media (min-width: 1150px) {
    padding: 2.5rem;
    grid-column: 2/7;
    margin: 0;
    margin-top: -7.5rem;
  }
  h4 {
    font-size: 1.5rem;
    line-height: 1.333em;
    margin: 0%;
    margin-right: 1rem;
    letter-spacing: 0;
    width: auto;
  }
  button {
    width: 12rem;
    height: 3rem;
    font-size: 1rem;
    line-height: 1.2em;
    background: var(--ex-beige);
    border: none;
    outline: none;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
  }
`;

const Help = styled.div`
  grid-area: help;
  grid-column: 1/6;
  background: var(--ex-main);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  margin-top: -6rem;
  margin-bottom: auto;
  padding: 0.625rem;
  display: none;
  @media (min-width: 1150px) {
    display: block;
    padding: 2rem 2.5rem;
    grid-column: 7/12;
    margin: 0;
    margin-top: -7.5rem;
  }
  h4 {
    font-size: 1.5rem;
    line-height: 1.333em;
    margin: 0%;
    margin-right: 1rem;
    letter-spacing: 0;
    text-align: center;
  }
  p {
    font-size: 1.5rem;
    line-height: 1.25em;
    text-align: center;
  }
`;

const SitemapColumn = styled.div`
  grid-area: sitemap;
  grid-column: 5/7;
  margin-bottom: 3rem;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  @media (min-width: 1440px) {
    grid-column: 6/8;
    margin-bottom: 0;
  }
  a {
    color: white;
    font-size: 1rem;
    line-height: 2em;
    :visited {
      color: white;
    }
  }
`;

const SupportColumn = styled.div`
  grid-area: support;
  grid-column: 7/9;
  margin-bottom: 3rem;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  color: white;
  @media (min-width: 1440px) {
    grid-column: 8/10;
    margin-bottom: 0;
  }
  button {
    background: none;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    line-height: 2em;
    cursor: pointer;
  }
  a {
    color: white;
    font-size: 1rem;
    line-height: 2em;
    :visited {
      color: white;
    }
  }
`;
