import React, { useContext } from 'react';
import styled from 'styled-components';
import { Modal, DemoForm } from 'components';
import LonerX from 'assets/svgs/loner-x.svg';
import XCombo from 'assets/svgs/x-combo.svg';
import { useIntersection } from 'hooks';
import { motion } from 'framer-motion';
import { ThemeContext } from 'context';

export default () => {
  const { isMobile } = useContext(ThemeContext);
  const { observerEntry, elRef } = useIntersection({ threshold: 0.25 });
  return (
    <Demo
      initial={{ opacity: 0, y: 50 }}
      animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
      ref={elRef}>
      {!isMobile && (
        <>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}>
            <XCombo />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}>
            <LonerX />
          </AnimatedSVG>
        </>
      )}
      <h3>Demo Event X</h3>
      <p>
        To fully experience a Virtual Event X in action, register here and see our Demo website{' '}
      </p>
      <button type="button">
        <Modal trigger="Request A Demo" modalContent={<DemoForm />} />
      </button>
    </Demo>
  );
};

const AnimatedSVG = styled(motion.div)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    10% {
      transform: rotate(0deg);
    }
  }
`;

const Demo = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  grid-column: 1/6;
  background: var(--ex-beige);
  padding: 3rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -12rem;
  margin-bottom: 3rem;
  transition: 0.75s;

  @media (min-width: 1150px) {
    margin-bottom: 6rem;
    grid-column: 2/12;
    margin-top: -12rem;
    padding: 3rem;
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      bottom: -10%;
      right: -5%;
    }
    :nth-child(2) {
      bottom: -10%;
      right: -10%;
      svg {
        animation: spin 3s ease-in-out infinite;
      }
    }
  }
  h3 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  p {
    font-size: 1.5rem;
    line-height: 1.25em;
    color: black;
    font-weight: 300;
    max-width: 800px;
    @media (min-width: 1150px) {
      font-size: 2rem;
      line-height: 1.5em;
    }
  }
  button {
    width: 166px;
    height: 46px;
    font-size: 16px;
    line-height: 19px;
    background: var(--ex-main);
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 0;
    @media (min-width: 1150px) {
      margin-top: 3rem;
    }
  }
`;
