import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useIntersection } from 'hooks';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      cainthusImage: file(relativePath: { eq: "assets/images/cainthus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      retinaImage: file(relativePath: { eq: "assets/images/retina-20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { observerEntry, elRef } = useIntersection({ threshold: 0.25 });
  return (
    <Cases
      initial={{ opacity: 0, y: 50 }}
      animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.25 }}
      ref={elRef}>
      <Case href="https://retina.ie" target="blank">
        <Img
          fluid={data.retinaImage.childImageSharp.fluid}
          alt="Virtual Events For Business Ireland- Case Study Retina 20"
        />
        <h4>Retina 2020 - Virtual Event Conference</h4>
        <h5>Fighting Blindness</h5>
      </Case>
      <Case href="https://dairystartupspotlight.com" target="blank">
        <Img
          fluid={data.cainthusImage.childImageSharp.fluid}
          alt="Virtual Events For Business Ireland- Case Study Global Dairy Tech Spotlight"
        />
        <h4>Dairy Tech Spotlight - International Virtual Event Expo</h4>
        <h5>Agri-Tech</h5>
      </Case>
    </Cases>
  );
};

const Cases = styled(motion.div)`
  transition: 0.75s;
  grid-column: 1/6;
  display: grid;
  grid-column-gap: 1.25rem;
  grid-row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Case = styled.a`
  display: flex;
  flex-direction: column;
  h4,
  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
    text-align: left;
    @media (min-width: 1150px) {
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
  }
  h4 {
    color: var(--ex-main);
    margin-top: 1rem;
    margin-bottom: 0.55rem;
    line-height: 1.25em;
  }
  h5 {
    color: black;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }
`;
