import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeContext } from 'context';

const ModalContent = (props) => {
  const { theme } = useContext(ThemeContext);
  return ReactDOM.createPortal(
    <ModalCover
      tag="aside"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      className="modal-cover"
      onKeyDown={props.onKeyDown}>
      <AnimatePresence>
        <ModalArea
          ref={props.modalRef}
          initial={{ opacity: 0, scale: 0.75 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: .75 }}
          transition={{ duration: .5 }}
          onClick={() => {
            props.closeModal();
          }}>
          <ModalBody
            onClick={(event) => {
              event.stopPropagation();
            }}>
            {!props.hideCloseButton && (
              <ModalClose
                ref={props.buttonRef}
                aria-label="Close Modal"
                aria-labelledby="close-modal"
                onClick={props.closeModal}
                theme={theme}>
                <HideVisual id="close-modal">Close</HideVisual>
                <ModalCloseIcon theme={theme} viewBox="0 0 40 40">
                  <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
                </ModalCloseIcon>
              </ModalClose>
            )}
            {props.content}
          </ModalBody>
        </ModalArea>
      </AnimatePresence>
    </ModalCover>,
    document.body
  );
};

const ModalCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;

const ModalArea = styled(motion.aside)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  padding: 20px;
`;

const ModalClose = styled.button`
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  padding: 0.5em;
  line-height: 1;
  background: ${({ theme }) => theme.main};
  border: 0;
  box-shadow: 0;
  z-index: 20;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      stroke: white;
      fill: white;
    }
  }
`;

const ModalCloseIcon = styled.svg`
  width: 30px;
  height: 30px;
  stroke-linecap: round;
  stroke-width: 3;
`;

const ModalBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 20px;
  form {
    margin-bottom: 20px;
  }
`;
const HideVisual = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;

export default ModalContent;
