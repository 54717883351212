import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import LonerX from 'assets/svgs/loner-x.svg';
import XCombo from 'assets/svgs/x-combo.svg';
import LonerX2 from 'assets/svgs/loner-x-2.svg';
import XCombo2 from 'assets/svgs/x-combo-2.svg';
import { useIntersection } from 'hooks';
import { motion } from 'framer-motion';
import { ThemeContext } from 'context';

export default ({ url }) => {
  const { isMobile } = useContext(ThemeContext);
  const { observerEntry, elRef } = useIntersection({ threshold: 0.25 });
  return (
    <StyledVideo
      initial={{ opacity: 0, y: 50 }}
      animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.25 }}
      ref={elRef}>
      {!isMobile && (
        <>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.25 }}>
            <LonerX2 />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.25 }}>
            <XCombo2 />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.25 }}>
            <XCombo />
          </AnimatedSVG>
          <AnimatedSVG
            initial={{ opacity: 0, y: 50 }}
            animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.25 }}>
            <LonerX />
          </AnimatedSVG>
        </>
      )}
      <ReactPlayer url={url} controls className="video-link" />
    </StyledVideo>
  );
};

const AnimatedSVG = styled(motion.div)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(25deg);
    }
    10% {
      transform: rotate(0deg);
    }
  }
`;

const StyledVideo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition-duration: 0.75s;
  @media (min-width: 1150px) {
    box-shadow: 0px 0px 0px 20px var(--ex-beige);
  }
  ${AnimatedSVG} {
    position: absolute;
    z-index: 1;
    transition-duration: 0.75s;
    transition-delay: 0.75s;
    :nth-child(1) {
      top: -10%;
      left: -5%;
    }
    :nth-child(2) {
      top: -10%;
      left: -5%;
      svg {
        animation: spin 3s ease-in-out infinite;
      }
    }
    :nth-child(3) {
      bottom: -10%;
      right: -5%;
    }
    :nth-child(4) {
      bottom: -9%;
      right: -8%;
      svg {
        animation: spin 3s ease-in-out infinite;
      }
    }
  }

  .video-link {
    position: relative;
    z-index: 2;
    width: 100% !important;
    height: 100% !important;
    padding-bottom: 56.25%;
    margin-right: 0;
    @media (min-width: 1150px) {
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
