import React from 'react';
import { Modal, ContactForm } from 'components';
import EventxPoster from 'assets/images/banner.jpg';
import EventxMP4 from 'assets/videos/eventx-banner.mp4';
import EventxWebM from 'assets/videos/eventx-banner.webm';
import styled from 'styled-components';

export default () => {
  return (
    <StyledHeader>
      <HeaderVideo autoPlay muted id="bgvid" poster={EventxPoster}>
        <source src={EventxMP4} type="video/mp4" />
        <source src={EventxWebM} type="video/webm" />
      </HeaderVideo>
      <HeaderContent>
        <div className="container">
          <div className="grid">
            <HeaderText>
              <h4>Welcome to Event X</h4>
              <h1>Virtual Events</h1>
              <p>
                Experts in virtual events for business. Where broadcast production meets digital marketing engagement to capture the attention of your online audience.
              </p>
              <button type="button">
                <Modal trigger="Contact Us" modalContent={<ContactForm />} />
              </button>
            </HeaderText>
          </div>
        </div>
      </HeaderContent>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  height: 100vh;
  min-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1000px) and (max-width: 1300px) and (min-height: 1000px) {
    height: 50vh;
  }
`;

const HeaderVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  object-fit:cover;
  object-position:center;
`;

const HeaderContent = styled.section`
  width: 100%;
  padding: 0.625rem;
  @media (min-width: 600px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    padding: 2rem;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

const HeaderText = styled.div`
  margin-top: 120px;
  color: white;
  grid-column: 1/7;
  @media (min-width: 1150px) {
    grid-column: 2/10;
  }
  @media (min-width: 1150px) and (min-height: 750px) {
    padding-bottom: 3rem;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.15em;
    margin-bottom: 1.25rem;
    font-weight: normal;
    text-align: left !important;
    @media (min-width: 768px) {
      font-size: 2rem;
      line-height: 1.25em;
    }
  }
  h1 {
    font-size: 2.75rem;
    line-height: 1.15em;
    margin-bottom: 1.25rem;
    text-align: left !important;
    @media (min-width: 768px) {
      font-size: 4.5rem;
      line-height: 1.25em;
    }
  }
  p {
    font-size: 1.125rem;
    line-height: 1.25em;
    text-align: left !important;
    @media (min-width: 768px) {
      font-size: 2rem;
      line-height: 1.25em;
    }
  }
  button {
    width: 166px;
    height: 46px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background: var(--ex-main);
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    @media (min-width: 768px) {
      margin-top: 2.5rem;
    }
  }
`;
