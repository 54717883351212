import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useIntersection } from 'hooks';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import SVG1 from 'assets/svgs/slide-1.svg';
import SVG2 from 'assets/svgs/slide-2.svg';
import SVG3 from 'assets/svgs/slide-3.svg';
import SVG4 from 'assets/svgs/slide-4.svg';
import SVG5 from 'assets/svgs/slide-5.svg';
import SVG6 from 'assets/svgs/slide-6.svg';
import Arrow from 'assets/svgs/arrow.svg';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

export default () => {
  const slides = [
    {
      id: 1,
      title: 'Create a customised Event ID & Engagement Strategy',
      text:
        'Every event needs to stand out and form part of your wider communications branding. We create full event branding ID and assets for you and your sponsors and speakers to leverage the maximum value from your key event.'
    },
    {
      id: 2,
      title: 'Register on a dedicated Event Microsite',
      text:
        'Consider whether your virtual event is for public viewing or for registered guests only. We recommend each online event has a registration page whereby we can monitor access and moderate comments. Choose from a simple platform registration link that you are familiar with, or for maximum brand value our secure Event X site structure allows for a fully customised audience experience.'
    },
    {
      id: 3,
      title: 'Maximise your audience with pre-event digital marketing',
      text:
        'Create a simple audience journey from awareness to registration for your virtual event. We combine owned media, paid media and earned media to reach your chosen audience. Use custom designed email marketing, social posts and digital advertising maximise registrations for your online event.'
    },
    {
      id: 4,
      title: 'Engage at Event with Live Stream, Key Notes and Q&A',
      text:
        'Consider your event as a live TV show experience. Choose a live studio set virtual or physical, show intros, sponsor slots, full produced show running order and Q&A wrap. Registered guests can interact with the host and each other using the livestream chat.'
    },
    {
      id: 5,
      title: 'Retain your Audience with our post event content repository',
      text:
        'All live content is made available for review by attendees post show. Edit highlights for social sharing. Allow for downloadable content of slide decks, info-graphics VoD and support materials to keep your audience returning for more. Update the content regularly and your Event X becomes a permanent destination for your audience.'
    },
    {
      id: 6,
      title: 'Measure ROI with Event Analytics, RFI’s and CPD Accreditations',
      text:
        'Your event is a key step of qualifying prospects through your lead generation pipeline. Gather all key metrics and consider their use to improve the audience experience. Add some event specific content such as continuous professional development (CPD) points, use polls and surveys to better understand the value they seek.'
    }
  ];

  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const selectPage = (selectedPage) => {
    setPage([selectedPage, 1]);
  };

  const index = wrap(0, slides.length, page);

  const { observerEntry, elRef } = useIntersection({ threshold: 0.25 });

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 10000);
    return () => clearTimeout(timer);
  }, [page]);

  return (
    <>
      <NumberContainer
        initial={{ opacity: 0, y: 50 }}
        animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.25 }}
        ref={elRef}>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(0);
          }}
          selected={slides[index].id === 1}>
          <span>01</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(1);
          }}
          selected={slides[index].id === 2}>
          <span>02</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(2);
          }}
          selected={slides[index].id === 3}>
          <span>03</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(3);
          }}
          selected={slides[index].id === 4}>
          <span>04</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(4);
          }}
          selected={slides[index].id === 5}>
          <span>05</span>
        </NumberBox>
        <NumberBox
          onClick={(e) => {
            e.stopPropagation();
            selectPage(5);
          }}
          selected={slides[index].id === 6}>
          <span>06</span>
        </NumberBox>
        <Next onClick={() => paginate(1)} />
        <Prev onClick={() => paginate(-1)} />
      </NumberContainer>

      <Container
        key={page}
        id={slides[index]}
        alt="slide"
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          duration: 0.5
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
          }
        }}>
        <AnimatePresence initial={false} custom={direction}>
          <Slide>
            <SVGContainer>
              {slides[index].id === 1 ? (
                <SVG1 />
              ) : slides[index].id === 2 ? (
                <SVG2 />
              ) : slides[index].id === 3 ? (
                <SVG3 />
              ) : slides[index].id === 4 ? (
                <SVG4 />
              ) : slides[index].id === 5 ? (
                <SVG5 />
              ) : (
                <SVG6 />
              )}
            </SVGContainer>
            <TextContainer>
              <h5>Step {slides[index].id}</h5>
              <h3>{slides[index].title}</h3>
              <h5>Summary</h5>
              <p>{slides[index].text}</p>
            </TextContainer>
          </Slide>
        </AnimatePresence>
      </Container>
    </>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Container = styled(motion.div)`
  grid-column: 1/6;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 1.25rem;
  grid-row-gap: 2.5rem;
  position: relative;
  max-width: 100%;
  overflow-x: hidden !important;

  height: auto;
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Slide = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  grid-column-gap: 3rem;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: 150px 1fr;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 250px 1fr;
  }
`;

const SVGContainer = styled.div`
  text-align: center;
  width: auto;
  svg {
    width: 80px;
    max-width: 100%;
    height: auto;
    @media (min-width: 768px) {
      width: 120px;
    }
    @media (min-width: 1150px) {
      width: 220px;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h3 {
    font-size: 1.5rem;
    line-height: 1em;
    color: black;
    margin-bottom: 1em !important;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 0px;
    @media (min-width: 768px) {
      font-size: 2rem;
      text-align: left;
    }
  }
  h5 {
    font-size: 0.75rem;
    line-height: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: var(--ex-main);
    text-transform: uppercase;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 0.75rem;
      text-align: left;
    }
  }
  p {
    color: black;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5em;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
`;

const Next = styled(Arrow)`
  position: absolute;
  right: 0;
  top: 50%;
  width: 1.794em;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Prev = styled(Arrow)`
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  top: 50%;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NumberContainer = styled.div`
  grid-column: 1/6;
  width: 100%;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(6, minmax(40px, 100px));
  justify-content: center;
  grid-gap: 0.5rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  position: relative;
  transition: 0.75s;
  @media (min-width: 768px) {
    margin-bottom: 4rem;
  }
  @media (min-width: 1150px) {
    grid-column: 3/11;
  }
`;

const NumberBox = styled.button`
  transition: 0.75s;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  border: 1px solid var(--ex-main);
  background: ${({ selected }) => (selected ? 'var(--ex-main) ' : 'var(--ex-beige)')};
  font-weight: bold;
  outline: none;
  cursor: pointer;
  :hover,
  :focus {
    border: 1px solid var(--ex-main);
    outline: none;
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: ${({ selected }) => (selected ? 'white' : '#C1BEAC')};
    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }
`;
