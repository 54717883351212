import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useWindowSize, useIntersection } from 'hooks';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import BenefitsX from 'assets/svgs/benefits-x.svg';
import YB1 from 'assets/svgs/your-benefit-1.svg';
import YB2 from 'assets/svgs/your-benefit-2.svg';
import YB3 from 'assets/svgs/your-benefit-3.svg';
import YB4 from 'assets/svgs/your-benefit-4.svg';
import YB5 from 'assets/svgs/your-benefit-5.svg';
import YB6 from 'assets/svgs/your-benefit-6.svg';
import CB1 from 'assets/svgs/customer-benefit-1.svg';
import CB2 from 'assets/svgs/customer-benefit-2.svg';
import CB3 from 'assets/svgs/customer-benefit-3.svg';
import CB4 from 'assets/svgs/customer-benefit-4.svg';
import CB5 from 'assets/svgs/customer-benefit-5.svg';
import CB6 from 'assets/svgs/customer-benefit-6.svg';

const yourBenefits = [
  {
    id: 1,
    text: `Enriched and fully integrated virtual event platform, from creative 3D sets to audience engagement strategy and more`
  },
  {
    id: 2,
    text: `Industry-leading online security and data protection`
  },
  {
    id: 3,
    text: `Customisable virtual event page for your audience`
  },
  {
    id: 4,
    text: `Streamline your business workflows with a single platform`
  },
  {
    id: 5,
    text: `Get valuable real-time insights into virtual event performance`
  },
  {
    id: 6,
    text: `Post event reporting, presentation decks, and sharable content`
  }
];

const customerBenefits = [
  {
    id: 7,
    text: `Fully integrated and seamless registration experience`
  },
  {
    id: 8,
    text: `Live Q&A sessions with options to interact and network with other attendees`
  },
  {
    id: 9,
    text: `Personalised agendas and save the date options`
  },
  {
    id: 10,
    text: `Easy to navigate event page and panel`
  },
  {
    id: 11,
    text: `Downloadable content that have been pre-approved`
  },
  {
    id: 12,
    text: `Easily accessible repository of post event content`
  }
];

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 300 : -300,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 300 : -300,
      opacity: 0
    };
  }
};

export default () => {
  const { observerEntry, elRef } = useIntersection({ threshold: 0.25 });

  const { windowWidth } = useWindowSize();

  const [benefits, setBenefits] = useState(yourBenefits);

  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const index = wrap(0, benefits.length, page);

  useEffect(() => {
    const benefitsTimer = setTimeout(() => paginate(1), 5000);
    return () => clearTimeout(benefitsTimer);
  }, [page]);

  return (
    <Container
      initial={{ opacity: 0, y: 50 }}
      animate={observerEntry.isIntersecting ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
      ref={elRef}>
      <ToggleBenefits>
        <ToggleButton
          type="button"
          selected={benefits === yourBenefits}
          onClick={() => setBenefits(yourBenefits)}>
          To You
        </ToggleButton>
        <span>/</span>
        <ToggleButton
          type="button"
          selected={benefits === customerBenefits}
          onClick={() => {
            setBenefits(customerBenefits);
          }}>
          To Your Audience & Sponsors
        </ToggleButton>
      </ToggleBenefits>
      {windowWidth >= 768 && (
        <Benefits>
          {benefits.map((benefit, i) => (
            <Benefit key={i}>
              <SVGContainer>
                {benefit.id === 1 ? (
                  <YB1 />
                ) : benefit.id === 2 ? (
                  <YB2 />
                ) : benefit.id === 3 ? (
                  <YB3 />
                ) : benefit.id === 4 ? (
                  <YB4 />
                ) : benefit.id === 5 ? (
                  <YB5 />
                ) : benefit.id === 6 ? (
                  <YB6 />
                ) : benefit.id === 7 ? (
                  <CB1 />
                ) : benefit.id === 8 ? (
                  <CB2 />
                ) : benefit.id === 9 ? (
                  <CB3 />
                ) : benefit.id === 10 ? (
                  <CB4 />
                ) : benefit.id === 11 ? (
                  <CB5 />
                ) : (
                  <CB6 />
                )}
              </SVGContainer>
              <Caption>
                <p>{benefit.text}</p>
              </Caption>
            </Benefit>
          ))}
        </Benefits>
      )}
      {windowWidth < 768 && (
        <Benefits
          key={page}
          id={benefits[index]}
          alt="slide"
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            duration: 0.25
          }}
          drag="x"
          dragConstraints={{ left: 10, right: 10 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}>
          <AnimatePresence initial={false} custom={direction}>
            <Benefit>
              <SVGContainer>
                {benefits[index].id === 1 ? (
                  <YB1 />
                ) : benefits[index].id === 2 ? (
                  <YB2 />
                ) : benefits[index].id === 3 ? (
                  <YB3 />
                ) : benefits[index].id === 4 ? (
                  <YB4 />
                ) : benefits[index].id === 5 ? (
                  <YB5 />
                ) : benefits[index].id === 6 ? (
                  <YB6 />
                ) : benefits[index].id === 7 ? (
                  <CB1 />
                ) : benefits[index].id === 8 ? (
                  <CB2 />
                ) : benefits[index].id === 9 ? (
                  <CB3 />
                ) : benefits[index].id === 10 ? (
                  <CB4 />
                ) : benefits[index].id === 11 ? (
                  <CB5 />
                ) : (
                  <CB6 />
                )}
              </SVGContainer>
              <Caption>
                <p>{benefits[index].text}</p>
              </Caption>
            </Benefit>
          </AnimatePresence>
        </Benefits>
      )}
      {windowWidth > 1150 && <StyledBenefitsX />}
    </Container>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Container = styled(motion.div)`
  position: relative;
  z-index: 1;
  grid-column: 1/6;
  @media (min-width: 900px) {
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const StyledBenefitsX = styled(BenefitsX)`
  bottom: -12.75em;
  left: -6.75em;
  position: absolute;
  width: 13.288em;
`;

const Benefits = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-row-gap: 1.25rem;
  grid-column-gap: 1.25rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
`;

const Caption = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  text-align: center;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.25rem;
    line-height: 1.25em;
    font-weight: 700;
    margin: 0;
  }
`;

const SVGContainer = styled.div`
  width: 100%;
  padding-top: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ex-beige);
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    path {
      transition: 0.5s;
    }
  }
`;

const Benefit = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid transparent;
  cursor: pointer;
  :hover {
    border: 1px solid var(--ex-main);
  }
  :hover ${SVGContainer} {
    svg {
      path {
        stroke: var(--ex-main);
      }
    }
  }
  :hover ${Caption} {
    color: var(--ex-main);
  }
  @media (max-width: 768px) {
    border: 1px solid var(--ex-main);
    ${SVGContainer} {
      svg {
        path {
          stroke: var(--ex-main);
        }
      }
    }
    ${Caption} {
      color: var(--ex-main);
    }
  }
`;

const ToggleBenefits = styled.div`
  display: flex;
  max-width: 450px;
  margin: 2rem auto;
  justify-content: space-between;
  align-items: flex-start;
  span {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.25em;
    color: var(--ex-main);
    margin: 0 1rem;
  }
`;

const ToggleButton = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  color: ${({ selected }) => (selected ? 'var(--ex-main)' : 'black')};
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25em;
  transition: 0.5s;
  width: auto;
  min-width: 80px;
  @media (min-width: 768px) {
    white-space: nowrap;
  }
`;
