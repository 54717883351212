import React, { useState, useRef } from 'react';
import ModalContent from '../ModalContent';
import ModalTrigger from '../ModalTrigger';

export default ({ trigger, modalContent, hideCloseButton, fullHeight }) => {
  const [isShown, setIsShown] = useState(false);
  const TriggerButton = useRef(null);
  const closeButton = useRef(null);
  const modal = useRef(null);

  const showModal = () => {
    setIsShown(true);
    // closeButton.focus();
    toggleScrollLock();
  };

  const closeModal = () => {
    setIsShown(false);
    toggleScrollLock();
  };
  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };
  const onClickOutside = (event) => {
    if (modal && modal.contains(event.target)) return;
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector('html').classList.toggle('scroll-lock');
  };

  return (
    <>
      <ModalTrigger showModal={showModal} buttonRef={TriggerButton} trigger={trigger} />
      {isShown ? (
        <ModalContent
          modalRef={modal}
          buttonRef={closeButton}
          closeModal={closeModal}
          content={modalContent}
          onKeyDown={onKeyDown}
          onClickOutside={onClickOutside}
          hideCloseButton={hideCloseButton}
        />
      ) : (
        <></>
      )}
    </>
  );
};