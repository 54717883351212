import React, { useState, useEffect, useContext } from 'react';
import { Modal, DemoForm } from 'components';
import { Link } from 'gatsby';
import Logo from 'assets/svgs/logo.svg';
import FB from 'assets/svgs/facebook-icon.svg';
import TW from 'assets/svgs/twitter-icon.svg';
import IN from 'assets/svgs/linkedin-icon.svg';
import INSTA from 'assets/svgs/insta-icon.svg';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ThemeContext } from 'context';

export default () => {
  const route = typeof window !== 'undefined' ? window.location.pathname : '';
  const {
    theme,
    isMobile,
    scrollTo,
    headerRef,
    aboutRef,
    benefitsRef,
    modulesRef,
    casesRef
  } = useContext(ThemeContext);
  const [navOpen, setNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(route !== '' ? route : '/');

  const handleScroll = () => {
    const scrollNavTop = window.scrollY;
    if (scrollNavTop >= 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const toggleActiveLink = (link) => {
    toggleNav();
    setActiveLink(link);
  };

  return (
    <Navbar theme={theme} scrolled={isScrolled} isMobile={isMobile}>
      <LogoButton
        aria-label="Home"
        onClick={() => {
          scrollTo(headerRef);
        }}>
        <StyledLogo />
      </LogoButton>

      {(!isMobile || navOpen) && (
        <Links
          navOpen={navOpen}
          initial={isMobile ? { opacity: 0, x: 400 } : { opacity: 0, y: -50 }}
          animate={isMobile ? { opacity: 1, x: 0 } : { opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}>
          <CustomLink
            className={activeLink === '/about/' ? 'active ' : null}
            to="#about"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(aboutRef);
              toggleActiveLink('/about/');
            }}>
            About
          </CustomLink>
          <CustomLink
            className={activeLink === '/modules/' ? 'active ' : null}
            to="#modules"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(modulesRef);
              toggleActiveLink('/modules/');
            }}>
            How It Works
          </CustomLink>
          <CustomLink
            className={activeLink === '/benefits/' ? 'active ' : null}
            to="#benefits"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(benefitsRef);
              toggleActiveLink('/benefits/');
            }}>
            Benefits
          </CustomLink>
          <CustomLink
            className={activeLink === '/cases/' ? 'active ' : null}
            to="#cases"
            onClick={(e) => {
              e.preventDefault();
              scrollTo(casesRef);
              toggleActiveLink('/cases/');
            }}>
            Case Studies
          </CustomLink>
          <CustomLink
            className={activeLink === '/demo/' ? 'active ' : null}
            to="#demo"
            onClick={(e) => {
              e.preventDefault();
            }}>
            <Modal trigger="Request A Demo" modalContent={<DemoForm />} />
          </CustomLink>

          {isMobile && (
            <Socials>
              <Social aria-label="Facebook" href="https://www.facebook.com/AgencyXdublin/" target="blank">
                <FB />
              </Social>
              <Social aria-label="Twitter" href="https://twitter.com/AgencyX_Dublin" target="blank">
                <TW />
              </Social>
              <Social aria-label="Linked In" href="https://www.linkedin.com/company/agencyx-dublin/" target="blank">
                <IN />
              </Social>
              <Social aria-label="Instagram" href="https://www.instagram.com/agency_x_dublin/" target="blank">
                <INSTA />
              </Social>
            </Socials>
          )}
        </Links>
      )}

      {isMobile && (
        <MenuToggle navOpen={navOpen} onClick={toggleNav} aria-label="Toggle Menu">
          <span />
        </MenuToggle>
      )}
    </Navbar>
  );
};

const Navbar = styled.nav`
  width: 1200px;
  max-width: 100%;
  height: 60px;
  display: grid;
  grid-template-areas: 'logo burger' 'links links';
  align-items: center;
  justify-content: space-between;
  background: var(--ex-beige);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 3;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
  @media (min-width: 1150px) {
    grid-template-areas: 'logo links';
    height: ${({ isScrolled }) => (isScrolled ? '60px' : '80px')};
  }
`;

const LogoButton = styled.button`
  height: 100%;
  width: 200px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;

const StyledLogo = styled(Logo)`
  max-height: 1.25rem;
  margin-left: 0.625rem;
  width: auto;
  grid-area: logo;
  @media (min-width: 1150px) {
    margin-left: 2.5rem;
  }
`;

const Links = styled(motion.div)`
  grid-area: links;
  display: ${({ navOpen }) => (navOpen ? 'flex' : 'none')};
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  height: calc(100vh - 60px);
  background: var(--ex-main);
  backdrop-filter: blur(2px);
  @media (min-width: 1150px) {
    display: flex;
    flex-direction: row;
    height: 100%;
    background: transparent;
    backdrop-filter: none;
  }
`;

const CustomLink = styled(Link)`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1em;
  color: black;
  letter-spacing: 0.1rem;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0.25em 0;
  text-align: center;
  div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 768px) {
    font-size: 3rem;
    margin-bottom: 0.5em 0;
  }

  @media (min-width: 1150px) {
    font-size: 1.1rem;
    padding-right: 2.5rem;
    height: 100%;
    width: auto;
    color: black;
    margin: 0;
    :first-child {
      border-top: none;
    }
    :nth-child(odd) {
      background: transparent;
    }
  }
  :last-child {
    background: var(--ex-main);
    color: white;
    padding: 0;
    @media (min-width: 1150px) {
      text-transform: uppercase;
      width: 14rem;
    }
  }
`;

const MenuToggle = styled.button`
  height: 60px;
  width: 60px;
  background: ${({ navOpen }) => (navOpen ? 'rgba(0,0,0.9)' : 'var(--ex-main)')};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  span {
    content: '';
    background: white;
    height: 3px;
    width: 20px;
    display: block;
    position: relative;
    transition: 0.5s;
    margin-top: -16px;
    transform: ${({ navOpen }) => (navOpen ? 'translateY(8px) rotate(45deg)' : 'none')};
    :before,
    :after {
      content: '';
      display: block;
      height: 3px;
      width: 20px;
      position: absolute;
      background: white;
      transition: 0.5s;
      transform-origin: center;
      opacity: 1;
    }
    :before {
      top: 8px;
      opacity: ${({ navOpen }) => (navOpen ? 0 : 1)};
    }
    :after {
      top: 16px;
      transform: ${({ navOpen }) => (navOpen ? 'translateY(-16px) rotate(-90deg)' : 'none')};
    }
  }
  @media (min-width: 1150px) {
    display: none;
  }
`;

const Socials = styled.div`
  display: flex;
  margin-top: 3rem;
`;

const Social = styled.a`
  background: black;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  transition: 0.75s;
  svg {
    width: auto;
    height: auto;
    max-width: 20px;
    max-height: 20px;
    width: auto;
    transition: 0.75s;
    path {
      fill: white;
    }
  }
  :hover {
    background: var(--ex-beige);
    svg {
      path {
        fill: var(--ex-main);
      }
    }
  }
`;
