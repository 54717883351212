import React, { createContext, useState, useEffect, useRef } from 'react';
import { useWindowSize } from 'hooks';

const ThemeContext = createContext();

const ThemeProvider = (props) => {
  const theme = {
    main: '#EB0045',
    beige: '#EFEDE4',
    muted: '#C1BEAC',
    light: '#E5E5E5'
  };
  const headerRef = useRef(null);
  const aboutRef = useRef(null);
  const modulesRef = useRef(null);
  const benefitsRef = useRef(null);
  const casesRef = useRef(null);

  const scrollTo = (ref) =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
    
  const { windowWidth } = useWindowSize();

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (windowWidth > 1150) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [isMobile, windowWidth]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        isMobile,
        headerRef,
        aboutRef,
        modulesRef,
        benefitsRef,
        casesRef,
        scrollTo
      }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider };
export default ThemeContext;
